// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkup-js": () => import("./../../../src/pages/checkup.js" /* webpackChunkName: "component---src-pages-checkup-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-trainingen-js": () => import("./../../../src/pages/trainingen.js" /* webpackChunkName: "component---src-pages-trainingen-js" */),
  "component---src-pages-werkenbij-js": () => import("./../../../src/pages/werkenbij.js" /* webpackChunkName: "component---src-pages-werkenbij-js" */),
  "component---src-pages-werkenmet-js": () => import("./../../../src/pages/werkenmet.js" /* webpackChunkName: "component---src-pages-werkenmet-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

